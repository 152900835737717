import React from "react"

const NotFound = () => {
  return (
    <div>
      <p>Sorry, nothing here.</p>
    </div>
  )
}

export default NotFound